const  ErrorCode = {
  200:{
    status:200,
    message:"Successfully got the respose"
  },
  401:{ status:200,
    message:"Successfully got the respose" 
  }
}

export default ErrorCode
